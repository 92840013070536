import "$styles/index.scss"
import "$styles/syntax-highlighting.css"
import "$styles/custom.css"


// Import all JavaScript & CSS files from src/_components
import components from "$components/**/*.{js,jsx,js.rb,css}"

console.info("Bridgetown is loaded!")

/*!
* Start Bootstrap - Creative v7.0.7 (https://startbootstrap.com/theme/creative)
* Copyright 2013-2023 Start Bootstrap
* Licensed under MIT (https://github.com/StartBootstrap/startbootstrap-creative/blob/master/LICENSE)
*/
//
// Scripts
// 

window.addEventListener('DOMContentLoaded', event => {

  // Navbar shrink function
  var navbarShrink = function () {
      const navbarCollapsible = document.body.querySelector('#mainNav');
      if (!navbarCollapsible) {
          return;
      }

      const img = navbarCollapsible.querySelector("img")
      if (window.scrollY === 0) {
          navbarCollapsible.classList.remove('navbar-shrink')
          img.src = "/images/shift_logo_white.png"
      } else {
          navbarCollapsible.classList.add('navbar-shrink')
          img.src = "/images/shift_logo_main.png"
      }
  };

  // Shrink the navbar 
  navbarShrink();

  // Shrink the navbar when page is scrolled
  document.addEventListener('scroll', navbarShrink);

  // Activate Bootstrap scrollspy on the main nav element
  const mainNav = document.body.querySelector('#mainNav');
  if (mainNav) {
      new bootstrap.ScrollSpy(document.body, {
          target: '#mainNav',
          rootMargin: '0px 0px -40%',
      });
  };

  // Collapse responsive navbar when toggler is visible
  const navbarToggler = document.body.querySelector('.navbar-toggler');
  const responsiveNavItems = [].slice.call(
      document.querySelectorAll('#navbarResponsive .nav-link')
  );
  responsiveNavItems.map(function (responsiveNavItem) {
      responsiveNavItem.addEventListener('click', () => {
          if (window.getComputedStyle(navbarToggler).display !== 'none') {
              navbarToggler.click();
          }
      });
  });

  // Activate SimpleLightbox plugin for portfolio items
  new SimpleLightbox({
      elements: '#portfolio a.portfolio-box'
  });

});

$(window).scroll(function() {
  if ($(".navbar").offset().top > 50) {
      $('#custom-nav').addClass('affix');
      $(".navbar-fixed-top").addClass("top-nav-collapse");
      $('.navbar-brand img').attr('src','shift_logo_main.png'); //change src
  } else {
      $('#custom-nav').removeClass('affix');
      $(".navbar-fixed-top").removeClass("top-nav-collapse");
      $('.navbar-brand img').attr('src','shift_logo_white.png')
  }   
});

